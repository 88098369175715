
import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class AuthView extends Vue {
  get redirectUrl (): string | null {
    const url = this.$route.query.url
    if (typeof url === 'string') return url
    return null
  }

  mounted () {
    this.verify()
  }

  async verify () {
    const result = await this.$store.dispatch('verify')
    if (result) {
      if (this.redirectUrl) {
        window.location.href = this.redirectUrl
      } else {
        await this.$router.push({ path: '/map' })
      }
    } else {
      await this.$store.dispatch('redirect', '/login')
    }
  }
}
