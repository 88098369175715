
import { Component, Vue } from 'vue-property-decorator'
import RecommendChart from './recommend.chart.vue'
import axios from 'axios'

export interface RecommendVariableSpecific {
  source: number; // 참고할 데이터 seq
  rank: number; // 상관 관계 순위
  field: string; // 상관관계 x순위 변수명
  desc: string; // 상관관계 x순위 변수 설명
  value: number; // 상관관계 x순위 상관계수
  sourceData?: {
    seq: number;
    title: string;
  };
  type?: 'correlation' | 'researcher';
}

export interface RecommendVariable {
  name: string;
  // MARK: 상관 관계
  correlation: Array<RecommendVariableSpecific>;
  // MARK: 연구자 추천 변수
  researcher: Array<RecommendVariableSpecific>;
}
@Component({ components: { RecommendChart } })
export default class RecommendView extends Vue {
  /*
   * LocalValues
   * */
  private chartData: Array<RecommendVariable> = [];
  private seed = 5e2;
  private pending = false
  private get seq () {
    const { params: { seq } } = this.$route
    return seq ? seq.toString() : ''
  }

  private get queries () {
    const { query } = this.$route
    const { PNU } = query
    return { PNU }
  }

  private get PNU () {
    const { PNU } = this.queries
    return typeof PNU === 'string' ? PNU.split(',') : []
  }

  /*
   * Methods
   * */
  protected mounted () {
    // console.log('RecommendView mounted!')
    this.load()
  }

  private async load () {
    try {
      this.pending = true
      const url = `/api/data/${this.seq}/recommend`
      const { data: response } = await axios.get<{ data: Array<RecommendVariable> }>(url, {})
      const { data } = response
      this.chartData = data
    } catch (e) {
      console.log(e)
    } finally {
      this.pending = false
    }
  }

  private selectChart (data: RecommendVariableSpecific) {
    // const { source } = data
    this.$emit('select-chart', data)
    // const { query, params: { seq: string } } = this.$route
    // const t = new Date().getTime().toString()
    // this.$router.push({ path: `/analysis/${source}`, query: { ...query, t } })
  }
}
