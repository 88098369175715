import app from '@/main'
import { LatLng } from '@/interface/interfaces'

export const Options = {
  // 초기 지도 옵션
  mapOptions () {
    return {
      // 중앙 위치값
      center: new app.prototype.$kakao.maps.LatLng(37.5721108, 126.9600000),
      // 축척
      level: 8
    }
  },
  // 기본 폴리곤 옵션
  basicPolygonOption () {
    return {
      fillColor: '#32F1FF',
      fillOpacity: 0.3
    }
  },
  // 구 폴리곤 옵션
  guPolygonOption () {
    return {
      strokeStyle: 'dashed'
    }
  },
  // 동 폴리곤 옵션
  dongPolygonOption () {
    return {
      fillColor: '#FF8C0A',
      fillOpacity: 0.4
    }
  },
  // 장소 폴리곤 옵션
  get trdarPolygonOption () {
    return {
      fillColor: '#B9062F',
      fillOpacity: 0.6
    }
  },
  // 중복된 폴리곤 옵션
  get duplicatePolygonOption () {
    return {
      fillColor: 'black',
      fillOpacity: 0.1
    }
  },
  managerOptions (map: any) {
    return {
      map: map,
      drawingMode: [
        app.prototype.$kakao.maps.drawing.OverlayType.CIRCLE
      ],
      guideTooltip: ['draw', 'drag', 'edit'],
      markerOptions: {
        draggable: false,
        removable: false
      },
      circleOptions: {
        draggable: false,
        removable: false,
        editable: false,
        strokeColor: '#39f',
        fillColor: '#0064FF',
        fillOpacity: 0.3
      }
    }
  },
  initPolygonOption (map: any, path: LatLng[], hole?: LatLng[][]) {
    return {
      // 폴리곤에 올릴 맵 설정
      map: map,
      // 폴리곤 그릴 경로
      path: hole ? [path, ...hole] : path,
      // 선 두께
      strokeWeight: 3,
      // 선 색깔
      strokeColor: 'black',
      // 선 투명도
      strokeOpacity: 0.5,
      // 선 스타일
      strokeStyle: 'solid',
      // 바탕색
      fillColor: '#32F1FF',
      // 바탕색 투명도
      fillOpacity: 0.3
    }
  }
}

export default Options
