
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ChartData } from '@/views/analysis/specific.vue'

@Component({})
export default class HistogramTable extends Vue {
  @Prop() private chartData!: ChartData;
  @Prop() private seq!: number;

  private nf (v: number) {
    return Intl.NumberFormat('ko-KR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(v)
  }

  rowClass () {
    return 'table-rows'
  }

  get tableData () {
    if (this.chartData.tableData) {
      return this.chartData.tableData.map(item => {
        return item
      })
    }
    return this.chartData.data.map(({ _id: name, sum, avg, min, max, std }) => {
      return {
        name,
        sum: this.nf(sum),
        avg: this.nf(avg),
        min: this.nf(min),
        max: this.nf(max),
        std: this.nf(std)
      }
    })
  }

  get headers () {
    return this.chartData.headers
  }
}
