import { VueConstructor } from 'vue'
import { KAKAO_API_KEY } from '@/enum/enums'

declare module 'vue/types/vue' {
  interface Vue {
    $kakao: {
      maps: any;
    };
  }
}

export default {
  install (Vue: VueConstructor, options?: { callback: () => void }) {
    const url = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${KAKAO_API_KEY.key}&autoload=false&libraries=services,clusterer,drawing`
    const script = document.createElement('script')
    script.src = url
    script.onload = () => {
      const { kakao } = window
      kakao.maps.load(() => {
        Vue.prototype.$kakao = kakao
        options?.callback()
      })
    }
    document.head.appendChild(script)
  }
}
