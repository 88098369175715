
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Chart, registerables } from 'chart.js'
import { ChartData } from '@/views/analysis/specific.vue'
import randomColor from 'randomcolor'
@Component({})
export default class HistogramChart extends Vue {
  @Prop() private chartData!: ChartData;
  @Prop() private index!: number;
  @Prop() private size!: number;
  @Prop() private seed!: number;
  @Prop() private id!: string;
  @Prop() private seq!: number;
  private chart?: Chart

  public $refs!: {
    chart: HTMLCanvasElement;
  };

  mounted () {
    this.renderChart()
  }

  private renderChart () {
    const { data, chartType, axisYLabel, axisXLabel } = this.chartData
    // const progress = (this.index + 1) / this.size
    const seed = ((this.index + 1) * this.seed).toFixed(0)
    const backgroundColor = randomColor({
      luminosity: 'dark',
      seed,
      alpha: 0.7,
      format: 'rgba'
    })
    const borderColor = randomColor({
      luminosity: 'dark',
      seed,
      alpha: 1,
      format: 'rgba'
    })
    const canvas = this.$refs.chart
    const ctx = canvas.getContext('2d')
    // TODO: 오류 처리
    if (!ctx) return
    Chart.unregister(...registerables)
    Chart.register(...registerables)
    this.chart?.destroy()

    const labels = this.seq === 45 ? this.index < 3
      ? data.map(({_id}) => _id.toString())
      : data.map(({year}) => year!.toString())
      : data.map(({_id}) => _id.toString())

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          label: axisYLabel.replace(/_/g, ' '),
          data: data.map(({ sum }) => sum),
          backgroundColor,
          borderColor,
          borderWidth: 1,
          barPercentage: 0.3,
          categoryPercentage: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: axisXLabel.replace(/_/g, ' '),
              font: {
                size: 14
              }
            },
            ticks: {
              autoSkip: true,
              // autoSkipPadding: 20,
              // padding: 5,
              stepSize: 1
              // maxTicksLimit: 1
            }
          },
          y: {
            title: {
              display: true,
              text: axisYLabel.replace(/_/g, ' '),
              font: {
                size: 14
              },
              padding: {
                top: 10,
                bottom: 10,
                y: 10
              }
            }
          }
        }
      }
    })
  }
}
