import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'
import { IAuth } from '@/interface/interfaces'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    _id: '',
    token: '',
    userId: '',
    userName: '',
    company: '',
    selectedCat1: '',
    selectedCat2: '',
    sectors: '',
    isLogin: false
  },
  getters: {
    _id: state => state._id,
    userId: state => state.userId,
    userName: state => state.userName,
    company: state => state.company,
    selectedCat1: state => state.selectedCat1,
    selectedCat2: state => state.selectedCat2,
    sectors: state => state.sectors,
    token: state => state.token,
    isLogin: state => state.isLogin
  },
  mutations: {
    token (state: IAuth, token: string) {
      state.token = token
    },
    verify (state: IAuth, payload: IAuth) {
      const { _id, company, sectors, userName, userId, selectedCat1, selectedCat2, isLogin } = payload
      state._id = _id
      state.company = company
      state.sectors = sectors
      state.userName = userName
      state.userId = userId
      state.selectedCat1 = selectedCat1
      state.selectedCat2 = selectedCat2
      state.isLogin = isLogin
    },
    clear (state: IAuth) {
      state._id = ''
      state.token = ''
      state.userId = ''
      state.userName = ''
      state.company = ''
      state.selectedCat1 = ''
      state.selectedCat2 = ''
      state.sectors = ''
      state.isLogin = false
    }
  },
  actions: {
    isTokenExpired (context) {
      const token = context.getters.token
      if (!token) return true
      return Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000
    },
    async verify (context) {
      const isTokenExpired = await context.dispatch('isTokenExpired')
      if (isTokenExpired) {
        context.commit('clear')
        return false
      }
      try {
        const token = context.getters.token
        const url = 'https://pmidata.co.kr/api/user/auth-verify'
        const { data: { result, token: newToken } } = await axios.get<{ result: boolean; token: string; }>(url, {
          headers: {
            authorization: `Bearer ${token}`
          }
        })
        if (result && token) context.commit('token', newToken)
        return result
      } catch (e) {
        console.warn(e)
        return false
      }
    },
    redirect (context) {
      context.commit('clear')
      document.location.href = 'https://pmidata.co.kr'
    },
    clear (context) {
      context.commit('clear')
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: process.env.NODE_ENV === 'production' }),
      removeItem: key => Cookies.remove(key)
    }
  })]
})
