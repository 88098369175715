import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import mapView from '@/views/map/index.vue'
import AnalysisView from '@/views/analysis/index.vue'
import AnalysisSpecificView from '@/views/analysis/specific.vue'
import AuthView from '@/views/auth/index.vue'
import NotFoundView from '@/views/404.vue'
import { NavigationGuardNext } from 'vue-router/types/router'
import store from '@/store'
Vue.use(VueRouter)
const IS_PROD = process.env.NODE_ENV === 'production'
const requireAuth = () => async (to: Route, from: Route, next: NavigationGuardNext) => {
  const result = await store.dispatch('verify')
  if (!result) {
    const host = IS_PROD ? 'https://public.pmidata.co.kr' : 'http://127.0.0.1:8080'
    const url = encodeURIComponent([host, to.path].join(''))
    const authUrl = [host, `/api/auth/[TOKEN]?targetUrl=${url}`].join('')
    document.location.href = `https://pmidata.co.kr/login/?url=${authUrl}`
  }
  return next()
}

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/map',
    name: 'map',
    component: mapView,
    beforeEnter: requireAuth()
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: AnalysisView,
    beforeEnter: requireAuth(),
    children: [
      {
        path: ':seq',
        name: 'analysis-specific',
        component: AnalysisSpecificView
      }
    ]
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFoundView
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
