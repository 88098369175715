
import axios from 'axios'
import app from '@/main'
import Options from '@/interface/options'
import { Component, Vue } from 'vue-property-decorator'
import { TRDAR_SE } from '@/enum/enums'
import { IGeoJson, IGeometry, IProperties, ISelectedTrdar, LatLng } from '@/interface/interfaces'
import SelectedList from '@/components/SelectedList.vue'

@Component({
  components: { SelectedList }
})
export default class Map extends Vue {
  private loading = false;
  private loadingMsg = '';
  private percent = 0;
  private basePath: LatLng[] = []
  // 카카오 맵
  private map: any = null;
  // 선택된 폴리곤
  private selectedPolygons: { polygon: any, PNU?: string, TRDAR_NO?: string, parentPNU?: string, La?: number, Ma?: number }[] = [];
  // 체크박스 선택된 시(v-model)
  private selectedSI: string[] = [];
  // 체크박스 선택된 구(v-model)
  private selectedGU: { PNU: string, NAME: string }[] = [];
  // 체크박스 선택된 동(v-model)
  private selectedDONG: { PNU: string, NAME: string }[] = [];
  // 체크박스 선택된 위치(v-model)
  private selectedTRDAR: ISelectedTrdar[] = [];
  // 만들어진 원들
  private selectedCircle: any[] = []
  // 선택된 사용자 정의 기준 (v-model)
  private selectedCategory = '';
  // 사용자가 선택한 기준 (행정구역, 좌표, 사용자 정의)
  private selectedMode = ''
  // 검색된 위치 드롭박스 구현 토글 (SearchedList 컴포넌트에 props 로 전달)
  private toggledTRDAR: string[] = [];
  // 좌표 + 반경 기준 모드 토글
  private drawMode = false;
  // 원 그리기 매니저
  private manager: any = null;
  // 행정구역 기준 메뉴 보기
  private displayCITY = false;
  // 좌표 기준 메뉴 보기
  private displayCOORDINATE = false;
  // 사용자 정의 기준 메뉴 보기
  private displayCUSTOM = false;

  private disabledSi = ['고양시', '성남시', '수원시', '안산시', '인양시', '용인시']
  private keyword = '';
  private trdarProperties: IProperties[] = []
  private displayMenu = false

  private SEOUL_CODE = '11'
  private GYEONGGI_CODE = '31'
  private INCHEON_CODE = '23'

  private mapLevel = 8
  private mapLat = 0
  private mapLon = 0
  private SEOUL_LAT = 37.5721108
  private SEOUL_LON = 126.9600000
  private INCHEON_LAT = 37.4745006
  private INCHEON_LON = 126.4648629
  private GYONGGI_LAT = 37.5721108
  private GYONGGI_LON = 126.9600000

  private lv1Data: IGeoJson[] = [];

  private seLv2Data: IGeoJson[] = [];
  private ggLv2Data: IGeoJson[] = [];
  private icnLv2Data: IGeoJson[] = [];

  private seLv3Data: IGeoJson[] = [];
  private ggLv3Data: IGeoJson[] = [];
  private icnLv3Data: IGeoJson[] = [];

  private dongData: IGeoJson[] = [];
  private marketData: IGeoJson[] = [];

  private TRDAR_SE () {
    return TRDAR_SE
  }

  private get category () {
    this.trdarProperties = this.marketData
      .filter(({ properties }) => properties.TRDAR_SE_1 === this.selectedCategory)
      .map(({ properties }) => properties)
      .filter(({ NAME }) => NAME !== "team204(팀204)"); // 전통시장 >  team204 상가 제외
    if (this.keyword) this.trdarProperties = this.trdarProperties.filter(data => data.NAME.includes(this.keyword))
    this.sortNAME(this.trdarProperties)
    return {
      NAME: this.selectedCategory,
      properties: this.trdarProperties
    }
  }

  private get lv1 () {
    const properties = this.getProperties(this.lv1Data)
    this.sortNAME(properties)
    return properties
  }

  private lv2 (PNU: string) {
    let properties: IProperties[] = []
    switch (PNU) {
      case this.SEOUL_CODE: {
        properties = this.getProperties(this.seLv2Data)
        break
      }
      case this.GYEONGGI_CODE: {
        properties = this.getProperties(this.ggLv2Data)
        break
      }
      case this.INCHEON_CODE: {
        properties = this.getProperties(this.icnLv2Data)
        break
      }
    }
    this.sortNAME(properties)
    return properties
  }

  // 해당 구의 동 properties 데이터 가져오기
  private lv3 (PNU: string) {
    let data: IGeoJson[] = []
    let properties: IProperties[] = []
    switch (PNU.substring(0, 2)) {
      case this.SEOUL_CODE: {
        data = this.seLv3Data.filter(({ properties }) => properties.PNU.substring(0, 5) === PNU)
        break
      }
      case this.GYEONGGI_CODE: {
        data = this.ggLv3Data.filter(({ properties }) => properties.PNU.substring(0, 5) === PNU)
        break
      }
      case this.INCHEON_CODE: {
        data = this.icnLv3Data.filter(({ properties }) => properties.PNU.substring(0, 5) === PNU)
        break
      }
    }
    properties = this.getProperties(data)
    this.sortNAME(properties)
    return properties
  }

  private checkedGU (PNU: string) {
    const foundGU = this.selectedGU.find(gu => gu.PNU === PNU)
    return !!foundGU
  }

  private toggleMenu () {
    this.displayMenu = !this.displayMenu
  }

  private removeDONG (PNU: string) {
    this.selectedDONG = this.selectedDONG.filter(dong => dong.PNU !== PNU)
    this.selectedPolygons.filter(item => item.PNU === PNU).forEach(data => data.polygon.setMap(null))
    this.selectedPolygons = this.selectedPolygons.filter(item => item.PNU !== PNU)
    if (this.selectedPolygons.length === 0) this.reset()
  }

  private removeTRDAR (TRDAR_NO: string) {
    this.selectedTRDAR = this.selectedTRDAR.filter(trdar => trdar.TRDAR_NO !== TRDAR_NO)
    const findPolygon = this.selectedPolygons.find(item => item.TRDAR_NO === TRDAR_NO)
    findPolygon?.polygon.setMap(null)
    this.selectedPolygons = this.selectedPolygons.filter(item => item.TRDAR_NO !== TRDAR_NO)
  }

  private setBoundDONG (PNU: string) {
    let geometry: IGeometry | null = null
    if (this.selectedMode === 'CITY') {
      switch (PNU.substring(0, 2)) {
        case this.SEOUL_CODE: {
          const data = this.seLv3Data.find(({ properties }) => properties.PNU === PNU)!
          geometry = data.geometry
          break
        }
        case this.GYEONGGI_CODE: {
          const data = this.ggLv3Data.find(({ properties }) => properties.PNU === PNU)!
          geometry = data.geometry
          break
        }
        case this.INCHEON_CODE: {
          const data = this.icnLv3Data.find(({ properties }) => properties.PNU === PNU)!
          geometry = data.geometry
          break
        }
      }
    } else {
      const data = this.dongData.find(({ properties }) => properties.PNU === PNU)!
      geometry = data.geometry
    }
    const coordinates = this.getCoordinates(geometry!.coordinates)
    const path = this.generatePath(coordinates)
    this.setBounds(path)
  }

  private setBoundTRDAR (TRDAR_NO: string) {
    const { geometry } = this.marketData.find(({ properties }) => properties.TRDAR_NO === TRDAR_NO)!
    const coordinates = this.getCoordinates(geometry.coordinates)
    const path = this.generatePath(coordinates)
    this.setBounds(path)
  }

  private getProperties (data: IGeoJson[]) {
    return data.map(({ properties }) => properties)
  }

  // 상위 주소 PNU 가져오기
  private parentPNU (PNU: string, checkSIGU: boolean) {
    if (checkSIGU) return PNU.slice(0, 2)
    return PNU.slice(0, 5)
  }

  // 지도 위에 폴리곤 만들기
  private generatePolygon (path: LatLng[], hole?: LatLng[][]) {
    return new this.$kakao.maps.Polygon(Options.initPolygonOption(this.map, path, hole))
  }

  private generatePath (coordinates: any) {
    const path: LatLng[] = []
    coordinates.flat().forEach(([lng, lat]: number[]) => {
      path.push(new this.$kakao.maps.LatLng(lat, lng))
    })
    return path
  }

  private generatePathSI (coordinates: IGeometry['coordinates']) {
    const path: LatLng[] = []
    for (let i = 0; i < coordinates.length; i++) {
      const [lng, lat] = coordinates[i]
      path.push(new this.$kakao.maps.LatLng(lat, lng))
    }
    return path
  }

  private generateMainSiPolygon (coordinates: [number, number][][][], checkedPNU: string) {
    // 1. 구멍 path 구하기
    const holeIndex = [1, 3]
    const hole = []
    for (const idx of holeIndex) {
      const tempHole = this.generatePathSI(coordinates[idx])
      hole.push(tempHole)
    }
    // 2. 메인 시 그리기: index 0
    const mainPath = this.generatePathSI(coordinates[0])
    const polygon = this.generatePolygon(mainPath, hole)
    this.selectedPolygons.push({ polygon, PNU: checkedPNU })
  }

  // 지도 위치로 이동
  private setBounds (path: LatLng[]) {
    const bounds = new window.kakao.maps.LatLngBounds()
    for (const p of path) {
      bounds.extend(p)
    }
    this.map.setBounds(bounds)
  }

  // 이름 오름차순 sort
  private sortNAME (properties: IProperties[]) {
    properties.sort((a, b) => a.NAME.toLowerCase() < b.NAME.toLowerCase() ? -1 : 1)
  }

  private generateBasePath (coordinates: any) {
    const path: LatLng[] = []
    console.log(coordinates.flat(1)[0])
    coordinates.flat(1)[0].forEach(([lng, lat]: any) => {
      path.push(new this.$kakao.maps.LatLng(lat, lng))
    })
    return path
  }

  private async toggleModeDisplay (mode: string) {
    this.reset()
    const check = (bool: boolean, mode: string) => {
      if (bool) {
        this.selectedMode = mode
      } else {
        this.selectedMode = ''
      }
    }
    switch (mode) {
      case 'CITY' : {
        try {
          if (!this.displayCITY && this.lv1Data.length === 0) {
            const { data: { features } } = await axios.get('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/SEGGICN_SIDO_4326.geojson')
            this.lv1Data = features
            // TODO: basePath
            // const { coordinates } = features.filter((feature: any) => feature.properties.PNU === this.SEOUL_CODE).map((data: { geometry: any }) => data.geometry)[0]
            // this.basePath = this.generateBasePath(coordinates)
            // console.log(this.basePath)
          }
          this.displayCOORDINATE = false
          this.displayCUSTOM = false
          this.displayCITY = !this.displayCITY
          check(this.displayCITY, mode)
        } catch (e) {
          console.error(e)
        }
        break
      }
      case 'COORDINATE' : {
        this.displayCITY = false
        this.displayCUSTOM = false
        this.displayCOORDINATE = !this.displayCOORDINATE
        check(this.displayCOORDINATE, mode)
        break
      }
      case 'CUSTOM' : {
        if (!this.displayCUSTOM && this.marketData.length === 0) {
          try {
            const { data: { features } } = await axios.get('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/SEKKICN_market_4326.geojson')
            this.marketData = features
          } catch (e) {
            console.error(e)
          }
        }
        this.displayCITY = false
        this.displayCOORDINATE = false
        this.displayCUSTOM = !this.displayCUSTOM
        check(this.displayCUSTOM, mode)
        break
      }
    }
  }

  private getCoordinates (coordinates: any) {
    return coordinates.flat()
  }

  private async getFeatures (url: string) {
    const { data: { features } } = await axios.get(url, {
      onDownloadProgress: (progressEvent) => {
        this.loadingMsg = 'Creating<br>Polygon!'
        if (progressEvent.total) {
          this.loading = true
          this.percent = (Math.round((Math.round((progressEvent.loaded * 100) / progressEvent.total)) / 10) * 10)
          if (progressEvent.loaded === progressEvent.total) {
            this.loading = false
            this.percent = 0
            this.loadingMsg = ''
          }
        }
      }
    })
    return features
  }

  // 지도 내 시 표시
  private async setLocationSI (checkedPNU: string) {
    const findPolygon = this.selectedPolygons.find(selectedPolygon => selectedPolygon.PNU === checkedPNU)
    if (findPolygon) {
      this.resetSI(checkedPNU)
      this.mapLevel -= 1
      return
    }
    const { geometry } = this.lv1Data.find(({ properties }) => properties.PNU === checkedPNU)!
    const coordinates = this.getCoordinates(geometry.coordinates)
    switch (checkedPNU) {
      case this.SEOUL_CODE: {
        for (const coordinate of coordinates) {
          const tempPath = this.generatePathSI(coordinate)
          const polygon = this.generatePolygon(tempPath)
          this.selectedPolygons.push({ polygon, PNU: checkedPNU })
        }
        try {
          if (this.seLv2Data.length === 0) {
            const features = await this.getFeatures('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/SE_LEVEL2_4326.geojson')
            this.seLv2Data = features
          }
        } catch (e) {
          console.error(e)
        }
        break
      }
      case this.INCHEON_CODE: {
        this.mapLevel = 9
        this.map.setCenter(new this.$kakao.maps.LatLng(this.INCHEON_LAT, this.INCHEON_LON))
        for (const coordinate of coordinates) {
          const tempPath = this.generatePathSI(coordinate)
          const polygon = this.generatePolygon(tempPath)
          this.selectedPolygons.push({ polygon, PNU: checkedPNU })
        }
        try {
          if (this.icnLv2Data.length === 0) {
            const features = await this.getFeatures('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/ICN_LEVEL2_4326.geojson')
            this.icnLv2Data = features
          }
        } catch (e) {
          console.error(e)
        }
        break
      }
      case this.GYEONGGI_CODE: {
        this.mapLevel = 10
        this.map.setCenter(new this.$kakao.maps.LatLng(this.GYONGGI_LAT, this.GYONGGI_LON))
        this.map.setLevel(this.mapLevel)
        this.generateMainSiPolygon(coordinates, checkedPNU)
        // 3. 나머지들 돌리기
        const holeIndex = [0, 1, 3]
        const islandCoordinates = coordinates.filter((v: any, i: number) => !holeIndex.includes(i))
        for (let i = 0, cdMax = islandCoordinates.length; i < cdMax; i++) {
          const tempPath = this.generatePathSI(islandCoordinates[i])
          const polygon = this.generatePolygon(tempPath)
          this.selectedPolygons.push({ polygon, PNU: checkedPNU })
        }
        try {
          if (this.ggLv2Data.length === 0) {
            const features = await this.getFeatures('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/GG_LEVEL2_4326.geojson')
            this.ggLv2Data = features
          }
        } catch (e) {
          console.error(e)
        }
        break
      }
    }
  }

  private async setLocationUnionGU (siPNU: string, checkedPNU: string) {
    let geometry: IGeometry | null = null
    let children: IGeoJson[] | null = null
    let PNU = ''
    const setData = (data: IGeoJson[]) => {
      const foundData = data.find(data => data.properties.PNU === checkedPNU)!
      geometry = foundData.geometry
      PNU = foundData.properties.PNU
    }
    const validateData = (features: IGeoJson[], data: IGeoJson[], index: number) => {
      for (const feature of features) {
        feature.properties.NAME = feature.properties.NAME.split(' ')[index]
        const foundData = this.ggLv3Data.find(({ properties }) => properties.PNU === feature.properties.PNU)
        if (foundData) {
          foundData.geometry.coordinates = [...foundData.geometry.coordinates, ...feature.geometry.coordinates]
          continue
        }
        data.push(feature)
      }
    }
    switch (siPNU) {
      case this.SEOUL_CODE: {
        try {
          setData(this.seLv2Data)
          if (this.seLv3Data.length === 0) {
            const features = await this.getFeatures('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/SE_LEVEL3_4326.geojson')
            validateData(features, this.seLv3Data, 0)
          }
          children = this.seLv3Data.filter(({ properties }) => properties.PNU.substring(0, 5) === checkedPNU)
        } catch (e) {
          console.error(e)
        }
        break
      }
      case this.GYEONGGI_CODE: {
        try {
          setData(this.ggLv2Data)
          if (this.ggLv3Data.length === 0) {
            const features = await this.getFeatures('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/GG_LEVEL3_4326.geojson')
            validateData(features, this.ggLv3Data, 1)
          }
          children = this.ggLv3Data.filter(({ properties }) => properties.PNU.substring(0, 5) === checkedPNU)
        } catch (e) {
          console.error(e)
        }
        break
      }
      case this.INCHEON_CODE: {
        try {
          setData(this.icnLv2Data)
          if (this.icnLv3Data.length === 0) {
            const features = await this.getFeatures('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/ICN_LEVEL3_4326.geojson')
            validateData(features, this.icnLv3Data, 0)
          }
          children = this.icnLv3Data.filter(({ properties }) => properties.PNU.substring(0, 5) === checkedPNU)
        } catch (e) {
          console.error(e)
        }
        break
      }
    }
    const parentPolygons = this.selectedPolygons.filter(polygon => polygon.PNU === siPNU)
    const findPolygon = this.selectedPolygons.find(selectedPolygon => selectedPolygon.PNU === checkedPNU)
    if (findPolygon) {
      this.selectedDONG = this.selectedDONG.filter(item => item.PNU.substring(0, 5) !== PNU)
      this.selectedTRDAR = this.selectedTRDAR.filter(item => item.PNU.substring(0, 5) !== PNU)
      this.selectedPolygons
        .filter(item => item.PNU?.slice(0, 5) === PNU || item.parentPNU?.substring(0, 5) === PNU)
        .forEach(item => item.polygon.setMap(null))
      this.selectedPolygons = this.selectedPolygons
        .filter(item => item.parentPNU?.slice(0, 5) !== PNU && item.PNU?.substring(0, 5) !== PNU)
      const selected = this.selectedGU.filter(data => data.PNU.substring(0, 2) === siPNU)
      if (selected.length === 1) {
        parentPolygons.forEach(data => data.polygon.setOptions(Options.basicPolygonOption()))
      }
      return
    }
    const coordinates = this.getCoordinates(geometry!.coordinates)
    for (const coordinate of coordinates) {
      const tempPath = this.generatePathSI(coordinate)
      const polygon = this.generatePolygon(tempPath)
      this.selectedPolygons.push({ polygon, PNU: checkedPNU })
    }
    parentPolygons.forEach(data => data.polygon.setOptions(Options.duplicatePolygonOption))
    for (const child of children!) {
      const { geometry, properties } = child
      const { NAME, PNU } = properties
      const coordinates = this.getCoordinates(geometry.coordinates)
      for (const coordinate of coordinates) {
        const tempPath = this.generatePathSI(coordinate)
        const polygon = this.generatePolygon(tempPath)
        polygon.setOptions(Options.trdarPolygonOption)
        const duplicatedDONG = this.selectedDONG.find(data => data.PNU === PNU)
        if (!duplicatedDONG) this.selectedDONG.push({ PNU, NAME })
        this.selectedPolygons.push({ polygon, PNU })
      }
    }
  }

  // 지도 내 동 표시
  private setLocationDong (checkedPNU: string) {
    let geometry: IGeometry | null = null
    let PNU = ''
    let NAME = ''
    const setData = (data: IGeoJson) => {
      geometry = data.geometry
      PNU = data.properties.PNU
      NAME = data.properties.NAME
    }
    switch (checkedPNU.substring(0, 2)) {
      case this.SEOUL_CODE: {
        const data = this.seLv3Data.find(({ properties }) => properties.PNU === checkedPNU)!
        setData(data)
        break
      }
      case this.GYEONGGI_CODE: {
        const data = this.ggLv3Data.find(({ properties }) => properties.PNU === checkedPNU)!
        setData(data)
        break
      }
      case this.INCHEON_CODE: {
        const data = this.icnLv3Data.find(({ properties }) => properties.PNU === checkedPNU)!
        setData(data)
        break
      }
    }
    const parentPNU = this.parentPNU(checkedPNU, false)
    const parentPolygon = this.selectedPolygons.find(item => item.PNU === parentPNU)?.polygon
    if (!parentPolygon) return
    const findPolygon = this.selectedPolygons.find(selectedPolygon => selectedPolygon.PNU === PNU)
    if (findPolygon) {
      const index = this.toggledTRDAR.indexOf(PNU)
      this.toggledTRDAR.splice(index, 1)
      this.selectedDONG = this.selectedDONG.filter(item => item.PNU !== PNU)
      this.selectedPolygons.filter(item => item.PNU === PNU || item.parentPNU === PNU).forEach(item => item.polygon.setMap(null))
      this.selectedPolygons = this.selectedPolygons.filter(item => item.parentPNU !== PNU && item.PNU !== PNU)
      return
    }
    const coordinates = this.getCoordinates(geometry!.coordinates)
    for (const coordinate of coordinates) {
      const tempPath = this.generatePathSI(coordinate)
      const polygon = this.generatePolygon(tempPath)
      polygon.setOptions(Options.trdarPolygonOption)
      const duplicatedDONG = this.selectedDONG.find(data => data.PNU === PNU)
      if (!duplicatedDONG) this.selectedDONG.push({ PNU, NAME })
      const duplicatedToggledTRDAR = this.toggledTRDAR.find(data => data === PNU)
      if (!duplicatedToggledTRDAR) this.toggledTRDAR.push(PNU)
      this.selectedPolygons.push({ polygon, PNU })
    }
  }

  // 지도 내 상권 표시
  private setLocationTRDAR (checkedTrdarNO: string, bound: boolean) {
    const { geometry, properties: { PNU: parentPNU, TRDAR_NO } } = this.marketData.find(data => data.properties.TRDAR_NO === checkedTrdarNO)!
    const findPolygon = this.selectedPolygons.find(selectedPolygon => selectedPolygon.TRDAR_NO === TRDAR_NO)
    if (findPolygon) {
      const index = this.selectedPolygons.indexOf(findPolygon)
      if (index > -1) {
        this.selectedPolygons[index].polygon.setMap(null)
        this.selectedPolygons.splice(index, 1)
        return
      }
    }
    const coordinates = this.getCoordinates(geometry.coordinates)
    const path = this.generatePath(coordinates)
    if (bound) this.setBounds(path)
    const polygon = this.generatePolygon(path)
    polygon.setOptions(Options.trdarPolygonOption)
    this.selectedPolygons.push({ polygon, TRDAR_NO, parentPNU })
  }

  private drawManager () {
    this.drawMode = !this.drawMode
    if (!this.drawMode) {
      if (this.manager) this.manager.cancel()
      return
    }
    this.manager = new this.$kakao.maps.drawing.DrawingManager(Options.managerOptions(this.map))
    this.manager.select(this.$kakao.maps.drawing.OverlayType.CIRCLE)
    this.manager.addListener('drawend', async (data: any) => {
      const { target } = data
      this.selectedCircle.push(target)
      const bounds = target.getBounds()
      try {
        if (this.dongData.length === 0) {
          const { data: { features } } = await axios.get('https://hdc2-pmi.s3.ap-northeast-2.amazonaws.com/S12617lbrcwo/SEKKICN_DONG_4326.geojson', {
            onDownloadProgress: (progressEvent) => {
              this.loadingMsg = 'Creating<br>Polygon!'
              if (progressEvent.total) {
                this.loading = true
                this.percent = (Math.round((Math.round((progressEvent.loaded * 100) / progressEvent.total)) / 10) * 10)
                if (progressEvent.loaded === progressEvent.total) {
                  this.loading = false
                  this.percent = 0
                  this.loadingMsg = ''
                }
              }
            }
          })
          this.dongData = features
        }
      } catch (e) {
        console.error(e)
      }
      this.dongData.forEach((data, index) => {
        const { geometry, properties: { PNU, NAME } } = data
        const coordinates = this.getCoordinates(geometry.coordinates)
        for (const coordinate of coordinates) {
          const path = this.generatePathSI(coordinate)
          if (bounds.contain(path[0])) {
            const { La, Ma } = NAME === '역삼2동' ? path[1] : path[0]
            const findPolygon = this.selectedDONG.find(polygon => polygon.PNU === PNU)
            if (!findPolygon) this.selectedDONG.push({ PNU, NAME })
            const findPolygons = this.selectedPolygons.filter(polygon => polygon.La === La && polygon.Ma === Ma)
            if (findPolygons.length > 0) {
              findPolygons.forEach(data => data.polygon.setMap(null))
              this.selectedPolygons = this.selectedPolygons.filter(polygon => polygon.La !== La && polygon.Ma !== Ma)
            }
            const polygon = this.generatePolygon(path)
            polygon.setOptions(Options.trdarPolygonOption)
            this.selectedPolygons.push({ polygon, PNU, La, Ma })
          }
        }
        this.percent = (Math.round((Math.round(((index + 1) * 100) / this.dongData.length)) / 10) * 10)
        if (this.percent === 100) this.loading = false
      })
      this.drawMode = false
    })
  }

  private next () {
    const dataPNU: { TRDAR_SE_1: string, PNU: string[] } = { TRDAR_SE_1: '', PNU: [] }
    const dataTRDAR: { TRDAR_SE_1: string, TRDAR_NO: string[] } = {
      TRDAR_SE_1: '',
      TRDAR_NO: []
    }
    switch (this.selectedMode) {
      case 'CITY':
      case 'COORDINATE': {
        dataPNU.TRDAR_SE_1 = '행정동'
        this.selectedDONG.forEach(({ PNU }) => {
          dataPNU.PNU.push(PNU)
        })
        break
      }
      case 'CUSTOM': {
        this.selectedTRDAR.forEach((TRDAR, i) => {
          if (i === 0) dataTRDAR.TRDAR_SE_1 = TRDAR.TRDAR_SE_1
          dataTRDAR.TRDAR_NO.push(TRDAR.TRDAR_NO)
        })
        break
      }
    }
    if (dataTRDAR.TRDAR_NO.length === 0 && dataPNU.PNU.length === 0) {
      return this.$bvModal.msgBoxOk(
        '선택된 장소가 없습니다',
        {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: '확인',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'pt-4',
          footerClass: 'p-2 border-top-0',
          centered: true
        }
      )
    }
    if (dataPNU.TRDAR_SE_1) {
      const { TRDAR_SE_1, PNU } = dataPNU
      this.$router.push({ path: 'analysis', query: { TRDAR_SE_1, PNU: PNU.join(',') } })
    } else if (dataTRDAR.TRDAR_SE_1) {
      const { TRDAR_SE_1, TRDAR_NO } = dataTRDAR
      this.$router.push({ path: 'analysis', query: { TRDAR_SE_1, TRDAR_NO: TRDAR_NO.join(',') } })
    }
  }

  private resetSI (PNU: string) {
    this.selectedSI = this.selectedSI.filter(data => data !== PNU)
    this.selectedPolygons.forEach(data => {
      if (data.PNU?.substring(0, 2) === PNU) {
        data.polygon.setMap(null)
      }
    })
    this.selectedPolygons = this.selectedPolygons.filter(data => data.PNU?.substring(0, 2) !== PNU)
    this.selectedGU = this.selectedGU.filter(data => data.PNU?.substring(0, 2) !== PNU)
    this.selectedDONG = this.selectedDONG.filter(data => data.PNU?.substring(0, 2) !== PNU)
  }

  private reset () {
    this.selectedSI = []
    this.selectedPolygons.forEach(item => item.polygon.setMap(null))
    this.selectedPolygons = []
    this.selectedGU = []
    this.selectedDONG = []
    this.selectedTRDAR = []
    this.selectedCategory = ''
    while (this.selectedCircle.length) {
      const circle = this.selectedCircle.shift()
      this.manager.remove(circle)
    }
    if (this.map && this.basePath.length > 0) this.setBounds(this.basePath)
    this.drawMode = false
  }

  mounted () {
    console.clear()
    const { map: mapRefs } = this.$refs
    if (mapRefs && this.$kakao) {
      this.mapLat = this.SEOUL_LAT
      this.mapLon = this.SEOUL_LON
      requestAnimationFrame(async () => {
        this.map = new this.$kakao.maps.Map(mapRefs, {
          center: new app.prototype.$kakao.maps.LatLng(this.mapLat, this.mapLon),
          level: this.mapLevel
        })
      })
    }
  }
}
