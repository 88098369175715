
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Chart, registerables } from 'chart.js'
import { ChartData } from '@/views/analysis/specific.vue'
import randomColor from 'randomcolor'
import {logger} from "html2canvas/dist/types/core/__mocks__/logger";
@Component({})
export default class PieChart extends Vue {
  @Prop() private chartData!: ChartData;
  @Prop() private index!: number;
  @Prop() private size!: number;
  @Prop() private seed!: number;
  private chart?: Chart

  public $refs!: {
    chart: HTMLCanvasElement;
  };

  mounted () {
    this.renderChart()
  }

  private renderChart () {
    const { data, axisYLabel } = this.chartData
    const canvas = this.$refs.chart
    const ctx = canvas.getContext('2d')
    // TODO: 오류 처리
    if (!ctx) return
    Chart.unregister(...registerables)
    Chart.register(...registerables)
    this.chart?.destroy()

    const basicColors = [
      '#ff99c8', '#fcf6bd', '#d0f4de', '#a9def9',
      '#e86e6d', '#e2e066', '#96ff66', '#86BED8',
      '#d54b6e', '#f7ae31', '#22d01e', '#d382ff',
      '#be3909', '#daea34', '#8dfced', '#4ea90f',
      '#de75ea', '#acc6f9', '#397938', '#eef27d'
    ]
    const colorLength = basicColors.length
    const bgColors = []

    for (let i = 0; i < data.length; i++) {
      bgColors.push(basicColors[i % colorLength])
    }

    this.chart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: data.map(({ _id }) => _id.toString()),
        datasets: [{
          label: axisYLabel.replace(/_/g, ' '),
          data: data.map(({ count }) => count),
          backgroundColor: bgColors,
          borderColor: '#fff',
          borderWidth: 2,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        plugins: {}
      }
    })
  }
}
