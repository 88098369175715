
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import HistogramChart from './histogram.chart.vue'
import PieChart from './pie.chart.vue'
import RecommendView, { RecommendVariableSpecific } from '@/views/analysis/recommend.vue'
import HistogramOverlayChart from '@/views/analysis/histogram.overlay.chart.vue'
import HistogramTable from '@/views/analysis/histogram.table.vue'
import { addImage, addTitle, firstPageAdd, isAddPageCheck_Before, pdfInit } from '@/utils/pdf'
import html2canvas from 'html2canvas'
import { CHART_TYPE } from '@/types/chart-types'

export interface ChartData {
  axisXLabel: string;
  axisYLabel: string;
  chartType: 'histogram' | 'histogram-overlay' | 'line' | 'pie';
  data: Array<{
    _id: number | string;
    sum: number;
    avg: number;
    min: number;
    max: number;
    std: number;
    count: number;
    year?: number | string;
  }>;
  tableData: Array<{
    name: string;
    sum: number;
    avg: number;
    min: number;
    max: number;
    std: number;
    year?: number | string;
  } | { [x: string]: number | string }>;
  headers: Array<{
    text: string,
    value: string,
    align: string,
    sortable: boolean,
  }>;
}

@Component({
  components: { HistogramChart, HistogramOverlayChart, RecommendView, HistogramTable, PieChart }
})
export default class AnalysisSpecificView extends Vue {
  @Prop() clickDownloadPDF!: boolean;
  private reportFirstPage = require('@/assets/report-1.png');
  private title = '';
  private chartData: Array<ChartData> = [];
  private selector: Array<string> = [];
  private pending = false;
  private seed = 5e2;
  private get seq () {
    const { params: { seq } } = this.$route
    return seq ? seq.toString() : ''
  }

  async getDataInfo (chartElement: HTMLElement): Promise<{ height: number; dataUrl: string }> {
    return new Promise((resolve) => {
      html2canvas(chartElement).then((canvas) => {
        const height = canvas.height
        const dataUrl = canvas.toDataURL('image/jpeg')
        return resolve({
          height: height,
          dataUrl: dataUrl
        })
      })
    })
  }

  @Watch('clickDownloadPDF')
  private async watchClickDownloadPDF () {
    if (!this.clickDownloadPDF) {
      this.$emit('completeDownload', false)
      return
    }

    if (this.chartData.length === 0) {
      this.$emit('completeDownload', false)
      const chartTitle = document.getElementById('chart-title')!.innerText
      return this.$bvModal.msgBoxOk(
        `${chartTitle}가(이) 존재하지 않습니다.`,
        {
          size: 'm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: '확인',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'pt-4',
          footerClass: 'p-2 border-top-0',
          centered: true
        }
      )
    }

    if (this.clickDownloadPDF) {
      document.getElementsByTagName('body')[0].style.height = '100vh'
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      const doc = pdfInit()
      const marginInfo = { marginTop: 10 }
      const draw = async (element: HTMLElement, type: CHART_TYPE, index?: number, lastPage?: boolean) => {
        const histogramDataInfo = await this.getDataInfo(element)
        addImage(histogramDataInfo.dataUrl, doc, element.offsetWidth, element.offsetHeight, type, index, lastPage)
      }

      isAddPageCheck_Before(marginInfo, doc)
      firstPageAdd(this.reportFirstPage, doc)

      const chartTitle = document.getElementById('chart-title')!
      addTitle(doc, chartTitle.innerText)
      for (let i = 1; i < this.chartData.length + 1; i++) {
        const histogramTitle = document.getElementById(`histogram-title-${i}`)
        const histogramChart = document.getElementById(`histogram-chart-${i}`)
        const histogramTable = document.getElementById(`histogram-table-${i}`)
        if (histogramTitle) await draw(histogramTitle, CHART_TYPE.HISTOGRAM_TITLE)
        if (histogramChart) await draw(histogramChart, CHART_TYPE.HISTOGRAM_CHART)
        if (histogramTable) await draw(histogramTable, CHART_TYPE.HISTOGRAM_TABLE)
        doc.addPage()
      }

      const recommendTitle = document.getElementById('recommend-chart-title')!
      addTitle(doc, recommendTitle.innerText)

      for (let i = 1; i < this.chartData.length + 1; i++) {
        const recommendChart = document.getElementById(`recommend-chart-${i}`)
        if (i === this.chartData.length) {
          if (recommendChart) await draw(recommendChart, CHART_TYPE.RECOMMEND_CHART, i, true)
          break
        }
        if (recommendChart) await draw(recommendChart, CHART_TYPE.RECOMMEND_CHART, i)
      }

      doc.save(`${chartTitle.innerText}.pdf`)
      this.$emit('completeDownload', false)

      document.getElementsByTagName('body')[0].style.height = '100%'
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }

  public $refs!: {
    container: HTMLDivElement
    histogram: HTMLDivElement
    croppedCanvas: HTMLCanvasElement
  }

  private get dummyChartData (): ChartData {
    return {
      axisXLabel: '',
      axisYLabel: '',
      chartType: 'histogram',
      data: [],
      tableData: [{
        name: '로딩 중',
        loading: '-',
        dummy: 0
      }],
      headers: [
        {
          text: '항목',
          value: 'loading',
          align: 'start',
          sortable: false
        },
        {
          text: 'Dummy',
          value: 'dummy',
          align: 'start',
          sortable: false
        }
      ]
    }
  }

  private get queries () {
    const { query } = this.$route
    const { PNU, TRDAR_SE_1, TRDAR_NO } = query
    return { PNU, TRDAR_SE_1, TRDAR_NO }
  }

  private get PNU () {
    const { PNU } = this.queries
    return typeof PNU === 'string' ? PNU.split(',').map(no => no.trim()) : []
  }

  private get TRDAR_SE_1 () {
    const { TRDAR_SE_1 } = this.queries
    return typeof TRDAR_SE_1 === 'string' ? TRDAR_SE_1 : '행정동'
  }

  private get TRDAR_NO () {
    const { TRDAR_NO } = this.queries
    return typeof TRDAR_NO === 'string' ? TRDAR_NO.split(',').map(no => no.trim()) : []
  }

  private created () {
    this.load()
  }

  private async load () {
    try {
      this.pending = true
      const url = `/api/data/${this.seq}/chart`
      const { data: response } = await axios.get<{ title: string; data: Array<ChartData>; selector: Array<string>; }>(url, {
        params: {
          PNU: this.PNU,
          TRDAR_SE_1: this.TRDAR_SE_1,
          TRDAR_NO: this.TRDAR_NO
        }
      })
      const { data, title, selector } = response
      this.title = title
      this.chartData = data
      this.selector = selector
    } catch (e) {
      console.log(e)
    } finally {
      this.pending = false
      // console.log(this.pending)
    }
  }

  private selectChart (data: RecommendVariableSpecific) {
    this.$emit('select-chart', data)
    // const { source } = data
    // const { query, params: { seq: string } } = this.$route
    // const t = new Date().getTime().toString()
    // this.$router.push({ path: `/analysis/${source}`, query: { ...query, t } })
  }
}
