
import axios from 'axios'
import {Component, Vue} from 'vue-property-decorator'
import {RecommendVariableSpecific} from '@/views/analysis/recommend.vue'

interface DataList {
  seq: number;
  title: string;
}

interface Selector {
  _id: string;
  TRDAR_SE_1: string;
  TRDAR_NO: number;
  NAME: string;
  SIGUN: number;
  PNU: number;
}

@Component({})
export default class AnalysisView extends Vue {
  /*
   * LocalValues
   */
  private pending = false
  private dataList: Array<DataList> = [];
  private selector: Array<Selector> = [];
  private seqModel = '';
  private keyword = '';
  private clickDownloadPDF = false
  private clickDownloadExcel = false;
  private years = ['2017', '2018', '2019', '2020', '2021'];
  private selectedYear = '';

  private get filteredDataList() {
    return this.dataList.filter(data => data.title.includes(this.keyword))
  }

  private scrollToHead(seq: string) {
    if (this.seqModel === seq) return
    this.$vuetify.goTo('#nav')
  }

  public $refs!: {
    container: HTMLDivElement;
    appBar: Vue;
    sheet: Vue;
  }

  /*
   * Methods
   * */

  protected created() {
    this.seqModel = this.seqParam
  }

  protected mounted() {
    this.load()

    if (this.$refs.sheet?.$el instanceof HTMLDivElement) {
      // this.$refs.sheet.$el.style.height = `${this.containerMaxHeight}px`
    }
    if (this.$refs.container instanceof HTMLDivElement) {
      // this.$refs.container.style.height = `${this.containerMaxHeight}px`
    }
    // if (this.$refs.sheet.$el instanceof HTMLDivElement) {
    //   this.$refs.sheet.$el.style.height = `${this.containerMaxHeight}px`
    // }
    // if (this.$refs.container instanceof HTMLDivElement) {
    //   this.$refs.container.style.height = `${this.containerMaxHeight}px`
    // }
  }

  private get containerMaxHeight() {
    return document.body.clientHeight - this.appbarHeight
  }

  private get appbarHeight() {
    return this.$refs.appBar ? this.$refs.appBar.$el.clientHeight : 0
  }

  private get selectedLabel() {
    // return this.selector.map(({ NAME }) => NAME).join(', ')
    return this.selector.map(({NAME}) => NAME)
  }

  protected get seqParam() {
    const {params} = this.$route
    const {seq} = params
    return seq ? seq.toString() : ''
  }

  protected get queries() {
    const {query} = this.$route
    const {PNU, TRDAR_SE_1, TRDAR_NO} = query
    return {PNU, TRDAR_SE_1, TRDAR_NO}
  }

  get PNU() {
    const {PNU} = this.queries
    return typeof PNU === 'string' ? PNU.split(',') : []
  }

  private get TRDAR_SE_1() {
    const {TRDAR_SE_1} = this.queries
    return typeof TRDAR_SE_1 === 'string' ? TRDAR_SE_1 : '행정동'
  }

  private get TRDAR_NO() {
    const {TRDAR_NO} = this.queries
    return typeof TRDAR_NO === 'string' ? TRDAR_NO.split(',').map(no => no.trim()) : []
  }

  private async datList() {
    return axios.get<{ list: Array<DataList> }>('/api/data/list')
  }

  private async selectorList() {
    return axios.get<Array<Selector>>('/api/data/selector', {
      params: {
        PNU: this.PNU,
        TRDAR_SE_1: this.TRDAR_SE_1,
        TRDAR_NO: this.TRDAR_NO
      }
    })
  }

  private downloadPDF() {
    if (!this.seqModel) {
      return this.$bvModal.msgBoxOk(
        '데이터의 종류를 선택해 주세요.',
        {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: '확인',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'pt-4',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }
      )
    }
    this.clickDownloadPDF = true
  }

  private completeDownload(check: boolean) {
    this.clickDownloadPDF = check
  }

  protected async load() {
    try {
      this.pending = true
      const {data: selector} = await this.selectorList()
      this.selector = selector
      const isGyeonggiIncheon = this.PNU.find((item: string) => item.substring(0, 2) !== '11')
      const {data: {list}} = await this.datList()
      this.dataList = isGyeonggiIncheon ? list : list.filter((li) => li.seq !== 45)
    } catch (e) {
      console.log(e)
    } finally {
      this.pending = false
    }
  }

  private changeData(seq: number) {
    this.$router.push({
      name: 'analysis-specific',
      params: {seq: seq.toString()},
      query: this.queries
    })
  }

  private routeBack() {
    this.$router.push('/map')
  }

  private get isFilteringYear() {
    return ['20', '21', '22', '23', '24', '25'].includes(this.seqParam);
  }

  private async downloadYear() {
    if (!this.seqParam) {
      return this.$bvModal.msgBoxOk(
        '데이터의 종류를 선택해 주세요.',
        {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: '확인',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'pt-4',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }
      )
    }
    this.clickDownloadExcel = true;
    this.selectedYear = '';
  }

  private async download() {
    this.pending = true;

    const year = this.selectedYear;

    try {
      if (!this.seqParam) {
        return this.$bvModal.msgBoxOk(
          '데이터의 종류를 선택해 주세요.',
          {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: '확인',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'pt-4',
            footerClass: 'p-2 border-top-0',
            centered: true,
          }
        )
      }
      const params = year
        ? {
          PNU: this.PNU,
          TRDAR_SE_1: this.TRDAR_SE_1,
          TRDAR_NO: this.TRDAR_NO,
          year,
        }
        :
        {
          PNU: this.PNU,
          TRDAR_SE_1: this.TRDAR_SE_1,
          TRDAR_NO: this.TRDAR_NO,
        }

      const {data} = await axios.get(`/api/data/${this.seqParam}/download`, {
        params,
        responseType: 'blob'
      });

      const href = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `data_${this.seqParam}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      // await new Promise(resolve => setTimeout(resolve, 1000))
    } catch (e) {
      console.log(e)
    } finally {
      this.pending = false
    }
  }

  private selectChart(data: RecommendVariableSpecific) {
    const {source} = data
    const {query, params: {seq: string}} = this.$route
    const t = new Date().getTime().toString()
    this.seqModel = source.toString()
    this.$router.push({path: `/analysis/${source}`, query: {...query, t}})
  }

  private closeExcelModal() {
    this.clickDownloadExcel = false;
    this.selectedYear = '';
  }
}
