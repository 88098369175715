
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Chart, registerables } from 'chart.js'
import { RecommendVariable } from '@/views/analysis/recommend.vue'
import randomColor from 'randomcolor'
@Component({})
export default class RecommendChart extends Vue {
  @Prop() private chartData!: RecommendVariable
  @Prop() private index!: number;
  @Prop() private size!: number;
  @Prop() private seed!: number;
  private chart?: Chart
  public $refs: {
    chart: HTMLCanvasElement
  }

  constructor () {
    super()
    this.$refs = Vue.prototype.$refs
  }

  created () {
    // console.log(this.chartData)
  }

  mounted () {
    this.renderChart()
  }

  private barColor (value: number, type: 'correlation' | 'researcher' = 'correlation') {
    if (type === 'researcher') return value > 0 ? 'rgba(255, 135, 0, 0.75)' : 'rgba(26, 0, 154, 0.75)'
    return value > 0 ? 'rgba(255, 99, 132, 0.75)' : 'rgba(54, 162, 235, 0.75)'
  }

  renderChart () {
    // const seed = ((this.index + 1) * this.seed).toFixed(0)
    // const backgroundColor = randomColor({
    //   luminosity: 'dark',
    //   seed,
    //   alpha: 0.7,
    //   format: 'rgba'
    // })
    // const borderColor = randomColor({
    //   luminosity: 'dark',
    //   seed,
    //   alpha: 1,
    //   format: 'rgba'
    // })
    const canvas = this.$refs.chart
    const ctx = canvas.getContext('2d')
    // TODO: 오류 처리
    if (!ctx) return
    Chart.unregister(...registerables)
    Chart.register(...registerables)
    this.chart?.destroy()

    const { name, correlation, researcher } = this.chartData
    const title = '추천 데이터(상관계수 기준)'
    // MARK: 상관계수
    const merge = [...correlation.map(item => {
      return {
        ...item,
        type: 'correlation'
      }
    }), ...researcher.map(item => {
      return {
        ...item,
        type: 'researcher'
      }
    })]
    // `${desc}\r\n(${type === 'correlation' ? '상관계수' : '연구자 추천'})`
    const labels = merge.map(({ desc, type }) => [desc, `(${type === 'correlation' ? '상관계수' : '연구자 추천'})`])

    const datasets = [
      {
        label: name,
        data: merge.map(({ value }) => value),
        backgroundColor: merge.map(({ value }) => this.barColor(value)),
        borderColor: merge.map(({ value }) => this.barColor(value)),
        borderWidth: 1,
        barPercentage: 1,
        categoryPercentage: 0.3
      }
    ]

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            // left: 10,
            // right: 10,
            // top: 10,
            // bottom: 10
          }
        },
        plugins: {
          title: {
            display: true,
            text: [title, name.replace('_', ' ')].join(' - '),
            position: 'bottom',
            align: 'center',
            padding: {
              top: 30,
              bottom: 10
            },
            font: {
              size: 18
            }
          },
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            min: -1,
            max: 1,
            suggestedMin: -1,
            suggestedMax: 1,
            ticks: {
              stepSize: 0.1
            }
          }
        },
        // events: ['click'],
        onClick: (e, item) => {
          if (!item.length) return
          const [{ datasetIndex, index }] = item
          const data = merge[index]
          this.$emit('select-chart', data)
        },
        onHover: (e, item) => {
          const [data] = item
          const { native } = e
          if (!(native?.target instanceof HTMLCanvasElement)) return
          native.target.style.cursor = data ? 'pointer' : 'default'
        },
        interaction: {
          mode: 'index'
        }
      }
    })
  }
}
