import { render, staticRenderFns } from "./histogram.overlay.chart.vue?vue&type=template&id=d1f739f8&scoped=true"
import script from "./histogram.overlay.chart.vue?vue&type=script&lang=ts"
export * from "./histogram.overlay.chart.vue?vue&type=script&lang=ts"
import style0 from "./histogram.overlay.chart.vue?vue&type=style&index=0&id=d1f739f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1f739f8",
  null
  
)

export default component.exports