
import {Component, Vue, Emit} from 'vue-property-decorator'
import Nav, {IBasicMenu, IMainMenu} from '@/components/nav/index.vue'

@Component({
  components: {
    Nav
  }
})
export default class AppView extends Vue {
  private imgSrc = require('@/assets/logo.svg')

  private get token() {
    return this.$store.getters.token
  }

  private mainTitle: IBasicMenu = {
    title: '통합 데이터 허브',
    url: 'https://pmidata.co.kr/main',
    options: {
      anchor: true
    }
  }

  private mainMenu: IMainMenu[] = [
    {
      title: '데이터 탐색',
      detailMenu: [
        {
          title: '뉴스/SNS 트렌드 데이터',
          url: `https://trend.pmidata.co.kr/mainpage.do?token=${this.token}`,
          options: {
            anchor: true
          }
        },
        {
          title: '모바일 행동 데이터',
          url: `https://mpd.pmidata.co.kr/api/users/dashboard/login/${this.token}`,
          options: {
            anchor: true
          }
        },
        {
          title: '공공 데이터',
          url: 'https://public.pmidata.co.kr/map',
          options: {
            anchor: true
          }
        },
        {
          title: '결제 데이터',
          url: `https://transaction.pmidata.co.kr/api/auth/${this.token}`,
          options: {
            anchor: true
          }
        }
      ]
    },
    {
      title: '데이터 마켓',
      detailMenu: [
        {
          title: '데이터 셋',
          url: 'https://pmidata.co.kr/data-set',
          options: {
            anchor: true
          }
        },
        {
          title: '융합 데이터',
          url: 'https://pmidata.co.kr/fusion-data',
          options: {
            anchor: true
          }
        },
        {
          title: '맞춤형 데이터 서비스',
          url: 'https://pmidata.co.kr/custom-data-service',
          options: {
            anchor: true
          }
        }
      ]
    },
    {
      title: '데이터 작업소',
      detailMenu: [
        {
          title: '시각화 만들기',
          url: 'https://pmidata.co.kr/visualization',
          options: {
            anchor: true
          }
        },
        {
          title: '마이 데이터 랩',
          url: 'https://pmidata.co.kr/my-data-hub',
          options: {
            anchor: true
          }
        },
      ]
    },
    {
      title: '알림',
      detailMenu: [
        {
          title: '플랫폼 소개',
          url: 'https://pmidata.co.kr/about-plat-form',
          options: {
            anchor: true
          }
        },
        {
          title: '공지사항',
          url: 'https://pmidata.co.kr/notice',
          options: {
            anchor: true
          }
        },
        {
          title: '자주하는 질문',
          url: 'https://pmidata.co.kr/faq',
          options: {
            anchor: true
          }
        },
        {
          title: '문의사항',
          url: 'https://pmidata.co.kr/qna',
          options: {
            anchor: true
          }
        }
      ]
    }
  ];

  private subMenu: IBasicMenu[] = [
    {
      title: '로그아웃',
      url: 'https://pmidata.co.kr/logout',
      options: {
        anchor: true
      }
    },
    {
      title: '마이페이지',
      url: 'https://pmidata.co.kr/mypage',
      options: {
        anchor: true
      }
    },
    {
      title: '이용안내',
      url: 'https://pmidata.co.kr',
      options: {
        anchor: true
      }
    },
    {
      title: '사이트맵',
      url: '#',
      options: {
        anchor: true,
        tab: false,
      },
    }
  ];

  get isLogin() {
    return this.$store.getters.isLogin
  }

  get userId() {
    return this.$store.getters.userId
  }

  get userName() {
    return this.$store.getters.userName
  }

  @Emit()
  logOut() {
    this.$store.dispatch('clear')
  }
}
