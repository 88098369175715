import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import axios from '@/plugins/axios'
import Kakao from '@/plugins/kakao'
import cookie from 'vue-cookies'
import '@/assets/style.css'
import BootstrapVue from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(cookie)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.prototype.$axios = axios // axios 전역 설정

const app = Vue.use(Kakao, {
  callback: () => {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
export default app
declare global {
  interface Window {
    kakao: any;
  }
}
