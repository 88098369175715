
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { ISelectedTrdar } from '@/interface/interfaces'

@Component
export default class SelectedList extends Vue {
  @Prop() selectedDONG?:{NAME:string, PNU:string}[]
  @Prop() selectedGU?:{NAME:string, PNU:string}[]
  @Prop() selectedTRDAR?: ISelectedTrdar[]
  @Prop() selectedCategory?: string
  @Prop() displayMenu!: boolean

  private wordingCheck (category: string) {
    return category === '관광특구' ? '가' : '이'
  }

  private get category () {
    return this.selectedTRDAR?.map((trdar, i) => {
      return trdar.TRDAR_SE_1
    })[0]
  }

  @Emit('removeDONG')
  removeDONG (PNU:string) {
    return PNU
  }

  @Emit('removeTRDAR')
  removeTRDAR (TRDAR_NO: string) {
    return TRDAR_NO
  }

  @Emit('setBoundDONG')
  setBoundDONG (PNU: string) {
    return PNU
  }

  @Emit('setBoundTRDAR')
  setBoundTRDAR (TRDAR_NO:string) {
    return TRDAR_NO
  }
}
